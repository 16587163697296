import React from 'react'
import { Container, Row, Col } from 'reactstrap'

import Layout from '../components/layouts/layout'
import Social from '../components/Social'
import ContactForm from '../components/ContactForm'

const IndexPage = () => (
  <Layout>
  	<Container className="contact-container">
  		<Row>
  			<Col md="12" lg="6" className="left-column mb-5">
  				<h1 className="mb-5">Me contacter</h1>
  				<Social />
  			</Col>
  			<Col md="12" lg="6">
  				<ContactForm />
  			</Col>
  		</Row>
    </Container>
     
  </Layout>
)

export default IndexPage
