import React from 'react'
import { Row, Col, Label, Button, CustomInput} from 'reactstrap'
import { AvForm, AvGroup, AvInput, AvFeedback } from 'availity-reactstrap-validation'


function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

export default class ContactForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      activeInput: {},
      terms: false,
      termsError: false,
      submit: false
    }

    this.validSubmit = this.validSubmit.bind(this)
    this.invalidSubmit = this.invalidSubmit.bind(this)
    this.focusHandler = this.focusHandler.bind(this)
    this.focusOutHandler = this.focusOutHandler.bind(this)
    this.handleTermsChange = this.handleTermsChange.bind(this)
  }

  validSubmit(event, values){
    event.preventDefault()
    const that = this
    if(!this.state.terms)
      this.setState({termsError: true})
    else
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({
          "form-name": 'pagecontact',
          ...values
        })
      })
      .then(() => that.setState({submit: true}))
      .catch(error => alert(error))
  }

  invalidSubmit(){
    this.setState({termsError: !this.state.terms})
  }

  focusHandler(event){
    let activeInput = Object.assign({}, this.state.activeInput)
    activeInput[event.target.name] = true
    this.setState({activeInput})
  }

  focusOutHandler(event){
    let activeInput = Object.assign({}, this.state.activeInput)
    activeInput[event.target.name] = (event.target.value !== '')
    this.setState({activeInput})
  }

  handleTermsChange(event){
    this.setState({
      terms : event.target.checked,
      termsError : !event.target.checked
    })
  }
  
  render() {
    if(!this.state.submit){
      return(
        <AvForm 
        name="pagecontact" 
        method="post"
        data-netlify="true" 
        data-netlify-honeypot="bot-field"
        onValidSubmit={this.validSubmit}
        onInvalidSubmit={this.invalidSubmit}
        className="contact-form"
        >
        <input type="hidden" name="form-name" value="pagecontact" />
        <p hidden>
            <label>
              Don’t fill this out:{" "}
              <input name="bot-field" />
            </label>
          </p>
        <Row>
          <Col md="6">
            <AvGroup className={`input-text ${(this.state.activeInput.nom ? 'focused' : '')}`}>
              <Label for="nom">Nom</Label>
              <AvInput onFocus={this.focusHandler} onBlur={this.focusOutHandler} name="nom" id="nom" required />
              <AvFeedback>Ce champ est requis</AvFeedback>
            </AvGroup>
          </Col>
          <Col md="6">
            <AvGroup className={`input-text ${(this.state.activeInput.prenom ? 'focused' : '')}`}>
              <Label for="prenom">Prénom</Label>
              <AvInput onFocus={this.focusHandler} onBlur={this.focusOutHandler} name="prenom" id="prenom" required />
              <AvFeedback>Ce champ est requis</AvFeedback>
            </AvGroup>
          </Col>

          <Col md="12">
            <AvGroup className={`input-text ${(this.state.activeInput.email ? 'focused' : '')}`}>
              <Label for="email">Email</Label>
              <AvInput onFocus={this.focusHandler} onBlur={this.focusOutHandler} name="email" id="email" type="email" required/>
              <AvFeedback>Champ incorrect</AvFeedback>
            </AvGroup>
          </Col>

          <Col md="12">
            <AvGroup className={`input-text ${(this.state.activeInput.tel ? 'focused' : '')}`}>
              <Label for="tel">Tel</Label>
              <AvInput onFocus={this.focusHandler} onBlur={this.focusOutHandler} name="tel" id="tel" type="text" required/>
              <AvFeedback>Champ incorrect</AvFeedback>
            </AvGroup>
          </Col>

          <Col md="12">
            <AvGroup>
              <Label for="content">Dites moi tout</Label>
              <AvInput name="content" id="content" type="textarea" required/>
              <AvFeedback>Ce champ est requis</AvFeedback>
            </AvGroup>
            </Col>

          <Col md="8" className="mt-3">
            <CustomInput className={(this.state.termsError ? 'error' : '')}onChange={this.handleTermsChange} type="checkbox" id="terms" label="J'accepte la chartre de confidentialités" />
          </Col>
          <Col md="4" className="mt-3 text-right">
            <Button className="submit-button" color="primary">Envoyer</Button>
          </Col>

        </Row>
      </AvForm>
      );
    }else{
      return (
        <div className="form-submission">
          <div className="content">
            <h2>Merci !</h2>
            <p>Votre message a été envoyé, je vous répondrai dans les plus brefs délais</p>
            <Button className="mt-4" onClick={() => this.setState({submit: false})}>Retour au formulaire</Button>
          </div>
        </div>
      );
    }

  }
}

